.form-container {
    text-align: center;
    margin-left: 100px;
    font-family: 'Roboto';
}

.form-container h1 {
    margin-bottom: 20px;
}

.pipeline-table {
    width: 100%;
}

.form-columns {
    display: flex;
    justify-content: center;
}

.column {
    flex: 1;
    padding: 0 20px; 
}

.form-group {
    margin-bottom: 20px; 
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    width: 350px; 
    text-align: left;
}

.form-group input {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form-group input.error {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 0.875em;
    margin-top: 5px;
}

.submit-row {
    margin-top: 20px;
    text-align: center;
}

.pipeline-table button {
    background-color: rgb(19, 31, 77);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-family: Roboto;
}

.pipeline-table button:hover {
    background-color: rgb(13, 23, 57);
}

.warning-message {
    color: red;
    font-weight: bold;
    margin-top: 5px;
}