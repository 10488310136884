.form-container {
    text-align: center;
    margin: 0 auto;
    font-family: 'Roboto';
    min-width: 60%;
    width: auto;
    height: auto; /* Allow the height to adjust based on content */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-container h1 {
    margin-bottom: 20px;
}

.form-columns {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.column {
    flex: 1;
    padding: 0 20px;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
}

.form-group input, 
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

textarea {
    min-height: 200px; /* Minimum height to provide some initial space */
    min-width: 80%;
    margin: 0; /* Remove margin to fit within the container */
    padding: 10px; /* Ensure consistent padding */
    padding-right: 50px;
    border-radius: 5px; /* Ensure consistent border radius */
    border: 1px solid #ddd; /* Ensure consistent border style */
    width: 98%; /* Subtract padding to fit within the container */
}

.submit-row {
    margin-top: 20px;
    text-align: center;
}

button {
    background-color: rgb(19, 31, 77);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-family: 'Roboto';
    font-size: 1em;
    margin: 0 10px;
}

button:hover {
    background-color: rgb(13, 23, 57);
}

button:disabled {
    background-color: rgb(200, 200, 200);
    cursor: not-allowed;
}
