.client-table-container {
    position: relative;
    top: 0;
    text-align: left;
    font-family: 'Roboto';
    font-size: large;
  }
  
  .client-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .client-table th, .client-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .client-table th {
    background-color: #f2f2f2;
  }
  
  .client-table tr:hover {
    background-color:#f5f5f5
  }
  
  .client-table tbody tr {
    transition: background-color 0.3s ease;
  }
  
  .client-table-container {
    position: relative;
    text-align: left;
  }
  
  .add-button {
    position: absolute;
    top: 85px; /* Adjust this value according to your header's height */
    right: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure modal is above other content */
  }
  
  .modal-content {
    background-color: white;
    padding: 40px; /* Increase padding for larger modal */
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); /* Increase box shadow for depth */
    position: relative; /* Required for absolute positioning of close button */
  }
  
  .modal-content h3 {
    margin-top: 0;
  }
  
  .modal-content label {
    display: block;
    margin-bottom: 10px; /* Increase margin for spacing */
  }
  
  .modal-content input {
    width: calc(100% - 16px);
    padding: 8px;
    margin-bottom: 10px; /* Increase margin for spacing */
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .modal-content button {
    width: 100%;
    padding: 10px 10px; /* Increase padding for larger button */
    margin-top: 10px; /* Increase margin for spacing */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px; /* Increase font size for better visibility */
    color: #555;
    z-index: 1001; /* Ensure button is above modal content */
  }
  
  
  