.pipeline-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .pipeline-table th, .pipeline-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .pipeline-table th {
    background-color: #f2f2f2;
  }
  
  .pipeline-table tr:hover {
    background-color: #f5f5f5;
  }
  
  .pipeline-table tbody tr {
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .pipeline-table button {
    background-color: rgb(19, 31, 77);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .pipeline-table button:hover {
    background-color: rgb(19, 31, 77);
  }
  