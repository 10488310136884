.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(249, 250, 251);;
}

.card {
    width: 30%; /* Adjust width as needed */
    min-width: 300px; /* Set a minimum width for responsiveness */
    max-width: 40%; /* Adjust max-width as needed */
    background-color: #ffffff; /* Optional: Change card background color */
    border-radius: 10px; /* Optional: Add border radius for card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow */
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-content {
    padding: 20px;
    text-align: center;
    position: relative;
}


.button-container {
    margin-top: 20px;
}

button {
    background-color: rgb(56, 113, 224);
    font-style: initial;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px; /* Adjust font size as needed */
    font-weight: 700; /* Make the text bold */
}

button:hover {
    background-color: rgba(19, 31, 77, 0.8); /* Optional: Adjust hover color */
}

